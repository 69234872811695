.box-info,
.box-warning,
.box-error,
.box-success {
  text-transform: uppercase;
  font-size: 0.625em;
  line-height: 2.4;
  display: inline-block;
  padding: 2px 10px;
  font-weight: 600;
}

.box-error {
  background-color: #ffe9e9;
  color: #eb5757;
}

.error-row .dsg-cell {
  background-color: #ffe9e9;
  color: #eb5757;
}

.box-warning.chat-tooltip:after,
.box-info.chat-tooltip:after,
.box-success.chat-tooltip:after,
.box-help.chat-tooltip:after,
.box-error.chat-tooltip:after {
  content: '\24D8';
  margin-left: 4px;
  display: inline-block;
  text-transform: lowercase;
}

.box-info {
  background-color: #c5e9ff;
  color: #007dc6;
}

.box-warning {
  background-color: #fff4e5;
  color: #663c00;
}

.box-success {
  background-color: #edf7ed;
  color: #1e4620;
}

.box-help {
  color: #007dc6;
}

.chat-tooltip {
  position: relative;
  display: inline-block;
  vertical-align: text-top;
  line-height: 1.3em;
}

.chat-tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
  line-height: 1.4;
  width: 250px;
  left: 0;
}

.chat-tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 10px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.chat-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
